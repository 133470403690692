'use strict';

(function() {
  class MoveTurbineCtrl {
    constructor($scope, Auth, Site, $uibModal, $state, usSpinnerService) {
      this.$scope = $scope;
      this.Site = Site;
      this.$uibModal = $uibModal;
      this.loading = false;
      this.usSpinnerService = usSpinnerService;
      Auth.isLoggedInAsync((loggedIn)=> {
        if (loggedIn) {
          // this.init();
        } else {
          $state.go('login');
        }
      });
    }

    openMoveTurbineModal(data = {}, update = false) {
      var modalInstance = this.$uibModal.open({
        size: 'sm',
        templateUrl: 'components/modal/turbine/moveTurbineModal/moveTurbineModal.html',
        controller: 'MoveTurbineModalCtrl',
        controllerAs: 'moveTurbine',
        backdrop: 'static',
        keyboard: false,
        resolve: {
          options: {
            title: 'Move Turbine',
            turbine: this.$scope.turbine
          }
        }
      });
      modalInstance.result.then(()=>{
      });
    }

    startSpin(spinner) {
      this.usSpinnerService.spin(spinner);
    }

    stopSpin(spinner) {
      this.usSpinnerService.stop(spinner);
    }
  }

  angular.module('windmanagerApp')
    .controller('MoveTurbineCtrl', MoveTurbineCtrl);
})();
